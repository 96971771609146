import React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"

import Layout from "../components/layout"
import Hammock from "../components/hammock"

import Seo from "../components/seo"

const options = {
    renderNode: {
        [BLOCKS.HEADING_2]: (node, children) => (
            <h2>
                <svg xmlns="http://www.w3.org/2000/svg" width={38} height={38} viewBox="0 0 512 512"><path d="M290.997 130.446c-29.519-29.602-77.679-29.602-107.277 0L22.199 291.882c-29.598 29.602-29.598 77.761 0 107.358l7.679 7.68 82.882 82.882c29.598 29.598 77.757 29.598 107.358 0l93.437-93.358 68.082-68.082c29.519-29.598 29.519-77.757 0-107.358l-90.64-90.558zm61.359 168.635l-60.48 60.48H44.28c-5.602-12.718-3.199-28.078 7.121-38.48l35.359-35.359 42.398 42.402c2.398 2.398 6.32 2.398 8.718 0 2.481-2.402 2.481-6.32 0-8.801l-42.32-42.32 36.598-36.597.043-.043 42.32 42.402c2.402 2.398 6.402 2.398 8.8 0 2.402-2.481 2.402-6.402 0-8.801l-42.398-42.32 36.636-36.641 42.402 42.32a6.036 6.036 0 008.718 0c2.402-2.402 2.402-6.32 0-8.718l-42.398-42.402 26.64-26.558c13.438-13.519 35.438-13.519 48.878 0l90.558 90.558c13.522 13.44 13.522 35.44.003 48.878zM511.999 30.223a30.07 30.07 0 00-8.824-21.39c-11.778-11.777-31.004-11.777-42.782 0l-12.519 12.519-11.641 11.434c-.91-.324-1.816-.652-2.738-.941-24.633-7.801-53.914-3.126-78.926 13.238-1.504.969-3 2.047-4.488 3.129-.266.168-.543.367-.805.528l.019.019c-.042.031-.09.058-.133.094l39.141 39.141 35.71 35.71 39.141 39.14c1.316-1.781 2.601-3.594 3.769-5.406 17.039-25.992 21.332-56.582 12.262-81.836l23.989-23.988a30.06 30.06 0 008.825-21.391zM322.852 44.673l-.313-.313-.112-.114-45.492 45.488 145.338 145.34 45.282-45.281.093-.098.113-.113z" /></svg>
                {children}
            </h2>
        ),
        [BLOCKS.EMBEDDED_ASSET]: node => (
            <GatsbyImage
                image={node.data.target.gatsbyImageData}
                alt={
                    node.data.target.description
                        ? node.data.target.description
                        : node.data.target.title
                }
                style={{ margin: "0 0 32px 0" }}
            />
        ),
    },
    renderText: text => {
        return text.split("\n").reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment]
        }, [])
    },
}

const Philosophy = ({ data, location }) => (
    <div className="body">
        <Seo
            pagetitle={`${documentToPlainTextString(JSON.parse(data.contentfulPhilosophy.title.raw))}`}
            pagedesc={`${documentToPlainTextString(JSON.parse(data.contentfulPhilosophy.content.raw)).slice(0, 70)}…`}
            pagepath={location.pathname}
            blogimg={`https:${data.contentfulPhilosophy.eyecatch.file.url}`}
            pageimgw={data.contentfulPhilosophy.eyecatch.file.details.image.width}
            pageimgh={data.contentfulPhilosophy.eyecatch.file.details.image.height}
        />
        <Hammock />
        <Layout>
            <main className="philosophyPost">
                <article className="philosophyPost__wrapper">
                    <div className="philosophyPost__postTitle">{renderRichText(data.contentfulPhilosophy.title, options)}</div>
                    <p className="philosophyPost__postDate"><time dateTime={data.contentfulPhilosophy.publishDate}>{data.contentfulPhilosophy.publishDateJP}</time></p>
                    <p className="philosophyPost__picture"><GatsbyImage image={data.contentfulPhilosophy.eyecatch.gatsbyImageData} alt={data.contentfulPhilosophy.eyecatch.description} className="philosophy__image" /></p>
                    <div className="philosophyPost__content">
                        {renderRichText(data.contentfulPhilosophy.content, options)}
                    </div>
                    <div className="partner">
                        <div className="partner__logo">
                            <p className="partner__crown"><StaticImage src="../images/logo.png" alt="ロゴ" quality={50} layout="constrained" placeholder="dominantColor" className="partner__image" /></p>
                            <p className="partner__title">Poncotsu Club</p>
                            <p className="partner__subtitle">by the poncotsu, for the poncotsu</p>
                        </div>
                        <div className="partner__container">
                            <p className="partner__text">ぽんこつクラブは、<br className="partner__br" />すべてのぽんこつを応援しています！</p>
                            <p className="partner__partnership">ぽんこつオフィシャルパートナー</p>
                        </div>
                    </div>
                </article>
            </main>
        </Layout>
    </div>
)

export const query = graphql`
    query($id: String!) {
        contentfulPhilosophy(id: { eq: $id }) {
            title {
                raw
            }
            publishDateJP:publishDate(formatString: "YYYY年MM月DD日")
            publishDate
            eyecatch {
                gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
                description
                file {
                    details {
                        image {
                            width
                            height
                        }
                    }
                    url
                }
            }
            content {
                raw
                references {
                    ... on ContentfulAsset {
                        contentful_id
                        __typename
                        gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR)
                        title
                        description
                    }
                }
            }
        }
    }
`

export default Philosophy