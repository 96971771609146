import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Parallax from "react-rellax"

const Hammock = () => (
  <div className="hammock">
    <p className="hammock__background"><StaticImage src="../images/hammock_background.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__wave1"><Parallax speed={-5}><StaticImage src="../images/hammock_wave1.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></Parallax></p>
    <p className="hammock__gomboatShadow"><StaticImage src="../images/hammock_gomboat_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__flower1Shadow"><StaticImage src="../images/hammock_flower1_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__flower2Shadow"><StaticImage src="../images/hammock_flower2_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__flower3Shadow"><StaticImage src="../images/hammock_flower3_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__flower4Shadow"><StaticImage src="../images/hammock_flower4_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__flower5Shadow"><StaticImage src="../images/hammock_flower5_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__wave2"><StaticImage src="../images/hammock_wave2.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__wave3"><StaticImage src="../images/hammock_wave3.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__palm1Shadow"><StaticImage src="../images/hammock_palm_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__palm2Shadow"><StaticImage src="../images/hammock_palm_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__bearShadow"><StaticImage src="../images/hammock_bear_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__bear"><StaticImage src="../images/hammock_bear.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__palm1"><StaticImage src="../images/hammock_palm.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__palm2"><StaticImage src="../images/hammock_palm.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__parasolShadow"><StaticImage src="../images/hammock_parasol_shadow.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__parasol"><StaticImage src="../images/hammock_parasol.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__gomboat"><StaticImage src="../images/hammock_gomboat.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__paddle"><StaticImage src="../images/hammock_paddle.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__flower1"><StaticImage src="../images/hammock_flower1.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__flower2"><StaticImage src="../images/hammock_flower2.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__flower3"><StaticImage src="../images/hammock_flower3.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__flower4"><StaticImage src="../images/hammock_flower4.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
    <p className="hammock__flower5"><StaticImage src="../images/hammock_flower5.png" alt="" quality={70} layout="fullWidth" placeholder="dominantColor" className="hammock__image" /></p>
  </div>
)

export default Hammock